import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaTags from "../components/media-tags"
import DocumentNotes from "../components/document-notes"
import PdfIcon from "../components/pdf-icon"
import Breadcrumbs from "../components/breadcrumb"

const PublicationTemplate = ({ pageContext }) => {
  const title = pageContext.node.frontmatter.title
  const html = pageContext.node.html
  const relatedMedia = pageContext.node.frontmatter.related_media[0]
  const crumbs = [
    {
      title: "Archives",
      slug: "/archives"
    },
    {
      title: "Publications",
      slug: "/archives/publications"
    },
    {
      title: pageContext.parentTitle,
      slug: `/archives/publications/${pageContext.parentSlug}`
    }
  ]

  return (
    <Layout>
      <SEO title={title} />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row id="content">
        <Col s="12" md="8" id="main-content">
          <Row>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Row>
          <Row>
            {/* prettier-ignore */}
            <a href={`${relatedMedia.frontmatter.path}/${relatedMedia.frontmatter.name}.${relatedMedia.frontmatter.ext}`}>
              <PdfIcon />
              <div>View document &gt;</div>
            </a>
          </Row>
        </Col>
        <Col>
          <div className="media_container_details sidebar" id="sidebar-second">
            <MediaTags tags={pageContext.node.frontmatter.tags} />
            <DocumentNotes />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default PublicationTemplate
